import React, { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UserContext } from '../context/UserContext';
import { FirebaseContext } from '../context/FirebaseContext';
import { makeStyles } from '@material-ui/core/styles';
import { app } from '../firebase/firebase';
import Link from '@material-ui/core/Link';

function Logout() {
    const classes = useStyles();
    const user = useContext(UserContext);

    useEffect(() => {
        logout()
    }, [])

    const logout = () => {
        app.auth().signOut().then(() => {
            user.update('')

        }).catch(e => {
            alert('Impossible de se déconnecter. Réessayez')
            console.error(e);
        })
    }
    return (
        <div>
            <Typography variant="h6" className={classes.content}>Vous êtes déconnectés de l'application...</Typography>
            <Button variant="contained" color="primary" className={classes.content} href="/login">Se connecter</Button>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        margin: theme.spacing(1)
    }
}));

export default Logout;