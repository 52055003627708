import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Classement from '../components/classement';
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { app } from '../firebase/firebase';
import { UserContext } from '../context/UserContext';

function Pools() {
    const classes = useStyles();
    let { name, id, refAdmin } = useParams();
    const history = useHistory();
    const [pools, setPools] = useState([]);
    const user = useContext(UserContext);

    useEffect(() => {
        app.firestore().collection('pools').where('refTournament', '==', id).get().then(snapshot => {
            var tmpPools = []
            snapshot.forEach(doc => {
                var tmp = doc.data();
                tmp = { ...tmp, poolId: doc.id }
                tmpPools.push(tmp)
            });
            setPools(tmpPools)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const checkMatchPlayed = async () => {
        if (pools.length === 0) {
            return false;
        }
        var result = false;
        await pools.map(item => {
            app.firestore().collection('matches').where('refPool', '==', item.poolId).get().then(snapshot => {
                snapshot.forEach(doc => {
                    if (doc.data().state !== 'toplay') {
                        result = true;
                    }
                })
            })
        })
        return result;
    }

    const isAdmin = () => {
        if (user.role === 'Administrateur') {
            return true;
        }
        return false;
    }

    const deleteTournament = async () => {
        var matchPlayed = await checkMatchPlayed();
        if (matchPlayed && !isAdmin()) {
            alert('Vous ne pouvez pas supprimer ce tournoi car des matchs ont déjà été joués.')
            return;
        }
        var result = window.confirm("Voulez-vous vraiment supprimer le tournoi ?\n Tous les résultats seront perdus.");
        if (result) {
            var refTournament = id;
            pools.map(item => {
                app.firestore().collection('matches').where('refPool', '==', item.poolId).get().then(snapshot => {
                    snapshot.forEach(doc => {
                        doc.ref.delete();
                    })
                })
                app.firestore().collection('pools').doc(item.poolId).delete();
            })
            app.firestore().collection('tournaments').doc(refTournament).delete();
            alert('Tournoi supprimé correctement !')
            history.push('/')
        }
    }
    const getBtnAdmin = () => {
        if (user.uid === refAdmin || isAdmin()) {
            return <Button onClick={() => deleteTournament()}>Supprimer le tournoi</Button>
        }
    }

    return (
        <div>
            <Typography variant="h6" className={classes.headingTournament}>{name}</Typography>
            {pools.map((item, index) => (
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container className={classes.container}>
                            <Grid item>
                                <Typography className={classes.headingPool}>{item.name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.headingPool}><Button onClick={() => history.push('/pools/' + item.poolId + '/scores')}>Voir les scores</Button></Typography>
                            </Grid>
                        </Grid>
                        <Classement players={item.players} />
                    </Paper>
                </div>
            ))}
            {getBtnAdmin()}
        </div>
    )
}
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    container: {
        justifyContent: 'space-between'
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',

    },
    chevron: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        textAlign: 'right'
    },
    headingTournament: {
        color: '#003454',
        paddingBottom: theme.spacing(2)
    },
    headingPool: {
        color: '#003454'
    },
    divider: {

    }
}));
export default Pools;