import React, { useState, useEffect } from 'react';
import { app } from '../firebase/firebase';
const UserContext = React.createContext();

function UserContextProvider(props) {
    const [id, setId] = useState('')
    const [role, setRole] = useState('');

    useEffect(() => {
        app.auth().onAuthStateChanged(user => {
            app.firestore().collection('players').doc(user.uid).get().then(doc => {
                setUid(user.uid, doc.data().role)
            }).catch(err => console.error(err))
        })
    }, [])

    // [A]
    const setUid = (uid, role) => {
        setUser({ ...user, uid: uid, role: role })
    }

    let [user, setUser] = React.useState({ uid: id, role: role, update: setUid })

    // [B]
    return (
        <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
    );
}

// [C]
let UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer }
