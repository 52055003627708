import React from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

function PlayerCell(props) {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <Grid container className={classes.container}>
                <Grid item xs={2}>
                    <Checkbox checked={props.player.checked} onChange={() => props.action(props.player)} />
                </Grid>
                <Grid item xs className={classes.item}>
                    <Typography>{props.player.name}</Typography>
                </Grid>
                <Grid item xs>
                    <Typography>{props.player.firstname}</Typography>
                </Grid>
            </Grid>
        </Card>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    card: {
        flexGrow: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        color: theme.palette.text.primary
    }
}));

export default PlayerCell;