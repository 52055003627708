import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Match from '../components/match';
import { app } from '../firebase/firebase';
import { UserContext } from '../context/UserContext';
import Button from '@material-ui/core/Button';
import ScoreDialog from '../components/ScoreDialog'

function Scores() {
    const classes = useStyles();
    let { id } = useParams();
    const [poolMatchs, setPoolMatchs] = useState([]);
    const user = useContext(UserContext);
    const [isAdmin, setAdmin] = useState(false)

    useEffect(() => {
        isTournamentAdmin();
        loadScore();
    }, [])

    useEffect(() => {
        loadScore();
    }, [isAdmin])

    const loadScore = () => {
        app.firestore().collection('matches').where('refPool', '==', id).get().then(snapshot => {
            var tmpMatchs = []
            snapshot.forEach(doc => {
                var tmp = doc.data()
                tmp = { ...tmp, id: doc.id }
                if (isAdmin) {
                    tmpMatchs.push(tmp)
                } else {
                    if (tmp.state === 'finish')
                        tmpMatchs.push(tmp)
                }
            })
            setPoolMatchs(tmpMatchs)
        }).catch(err => {
            console.error(err)
        })
    }

    const isTournamentAdmin = () => {
        if (user.role === 'Administrateur') {
            setAdmin(true)
        } else if (user.role === 'Responsable') {
            app.firestore().collection('pools').doc(id).get().then(doc => {
                let refTournament = doc.data().refTournament;
                app.firestore().collection('tournaments').doc(refTournament).get().then(doc => {
                    if (doc.data().refAdmin === user.uid) {
                        setAdmin(true)
                    }
                }).catch(err => {
                    console.error(err)
                })
            }).catch(err => {
                console.error(err)
            })
        }
    }

    const openDialog = (index) => {
        let newMatchs = [...poolMatchs];
        newMatchs[index].open = true;
        setPoolMatchs(newMatchs);
    }

    const closeDialog = (index) => {
        let newMatchs = [...poolMatchs];
        newMatchs[index].open = false;
        setPoolMatchs(newMatchs);
    }

    const editScore = (index) => {
        openDialog(index);
    }

    const getEditBtn = (index) => {
        if (user.role === 'Administrateur' || isAdmin) {
            return <Button className={classes.editBtn} onClick={() => editScore(index)}>Edit</Button>
        }
    }

    const getView = () => {
        if (poolMatchs.length === 0) {
            return <Typography variant="h5" component="h5">Aucun match n'a encore été joué</Typography>
        } else {
            return <div>
                {poolMatchs.map((item, index) => (
                    <div key={index}>
                        <ScoreDialog open={item.open} close={() => closeDialog(index)} match={item} reload={loadScore} admin={isAdmin} type={'pool'}/>
                        <Match match={item} />
                        {getEditBtn(index)}
                    </div>
                ))}
            </div>
        }
    }

    return (
        getView()
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    container: {
        justifyContent: 'space-between'
    },
    paper: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',

    },
    editBtn: {
        marginBottom: theme.spacing(2),
    }
}));

export default Scores;