import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { app } from '../firebase/firebase'
import { useParams } from "react-router-dom";
import RoundInfo from '../components/roundInfo';
import { UserContext } from '../context/UserContext';

//Display a draw tournament with each round
function Draw() {
    const [value, setValue] = useState(0);
    let { name, id, refAdmin } = useParams();
    const [draw, setDraw] = useState()
    const history = useHistory();
    const user = useContext(UserContext);

    useEffect(() => {
        app.firestore().collection('draws').where('refTournament', '==', id).get().then(snapshot => {
            snapshot.forEach(doc => {
                console.log(doc)
                setDraw(doc.data())
            });
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const deleteTournament = () => {
        app.firestore().collection('draws').where('refTournament', '==', id).get().then(snapshot => {
            snapshot.forEach(doc => {
                doc.ref.delete()
            });
        }).catch(err => {
            console.log(err)
        })
        app.firestore().collection('tournaments').doc(id).delete()
            .then(() => {
                alert('Tournoi supprimé correctement.')
                history.push('/')
            })
            .catch(() => alert('Erreur lors de la suppression du tournoi !'))
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTabs = () => {
        var items = [];
        if (draw !== undefined)
            for (var i = 0; i <= draw.rounds - 1; i++) {
                var nb = i + 1;
                items.push(<Tab label={"Tour " + nb} />)
            }
        return items;
    }

    const getRound = () => {
        var items = [];
        if (draw !== undefined)
            for (var i = 0; i <= draw.rounds; i++) {
                items.push(<RoundInfo value={value} index={i} round={i} matchs={draw.matchs} tournamentID={draw.refTournament} />);
            }
        return items;
    }

    const isAdmin = () => {
        if (user.role === 'Administrateur') {
            return true;
        }
        return false;
    }

    const getBtnAdmin = () => {
        if (user.uid === refAdmin || isAdmin()) {
            return <Button onClick={() => deleteTournament()}>Supprimer le tournoi</Button>
        }
    }

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
            >
                {getTabs()}
            </Tabs>
            {getRound()}
            {getBtnAdmin()}
        </div>
    )
}

export default Draw;