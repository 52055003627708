import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function Classement(props) {
    const classes = useStyles();
    const [sortPlayers, setSortPlayers] = useState([])

    const compare = (a, b) => {
        if (a.matchWon > b.matchWon) {
            return -1
        } else if (a.matchWon < b.matchWon) {
            return 1
        } else {
            if (a.setWon > b.setWon) {
                return -1
            } else if (a.setWon < b.setWon) {
                return 1
            } else {
                if (a.gameWon > b.gameWon) {
                    return -1
                } else if (a.gameWon < b.gameWon) {
                    return 1
                } else {
                    if (a.name < b.name) {
                        return -1
                    } else {
                        return 1
                    }
                }
            }
        }
    }

    useEffect(() => {
        setSortPlayers(props.players.sort(compare));
    }, [])

    return (
        <div className={classes.root}>
            {sortPlayers.map((item, index) => (
                <ExpansionPanel key={index}>
                    <ExpansionPanelSummary expandIcon={<KeyboardArrowDown />}>
                        <Grid container className={classes.container}>
                            <Grid item xs={1}><Typography variant="h6">{index + 1}</Typography></Grid>
                            <Grid item xs><Typography variant="h6">{item.name}</Typography></Grid>
                            <Grid item xs><Typography variant="h6">{item.firstname}</Typography></Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List>
                            <ListItem><Typography>Matchs gagnés: {item.matchWon}</Typography></ListItem>
                            <ListItem><Typography>Sets gagnés: {item.setWon}</Typography></ListItem>
                            <ListItem><Typography>Jeux gagnés: {item.gameWon}</Typography></ListItem>
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))
            }
        </div>

    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        borderWidth: 10,
        borderColor: '#003454'
    },
    container: {
        justifyContent: 'space-between',
    }
}));

export default Classement;