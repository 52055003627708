import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { app } from '../firebase/firebase';
import { UserContext } from '../context/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';

function Players() {
    const [players, setPlayers] = useState([]);
    const [search, setSearch] = useState([])
    const classes = useStyles();
    const history = useHistory();
    const [options, setOptions] = useState([])
    const user = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user.role !== 'Administrateur') {
            alert('Vous n\'êtes pas autorisé à ouvrir cette page !');
            history.goBack()
        } else {
            getPlayers();
        }
    }, [])

    useEffect(() => {
        setOptions(players);
    }, [players])

    useEffect(() => {
        if (search.length) {
            const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
            setOptions(players.filter(option =>
                option.name.match(searchPattern) || option.firstname.match(searchPattern)
            ));
        } else {
            setOptions(players);
        }
    }, [search])

    const getPlayers = () => {
        app.firestore().collection('players').get().then(snapshot => {
            var tmpPlayers = [];
            snapshot.forEach(doc => {
                console.log(doc.data())
                var tmp = doc.data();
                tmp = { ...tmp, uid: doc.id };
                tmpPlayers.push(tmp);
            })
            setPlayers(tmpPlayers);
            setLoading(false);
        })
    }

    const deletePlayer = (uid) => {
        app.firestore().collection('players').doc(uid).delete().then(() => {
            alert('Joueur supprimé avec succès !')
        }).catch(err => {
            alert('Oups: ' + err.message)
        })
    }

    const getView = () => {
        if (loading) {
            return <CircularProgress />
        } else {
            return <div className={classes.root}>
                <TextField label="Chercher un joueur"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => setSearch(e.target.value.split(' '))} />
                <List>
                    {options.map((item, index) => (
                        <ListItem key={index}><Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid item xs><Typography>{item.firstname} {item.name}</Typography></Grid>
                                <Grid item><Button onClick={() => history.push('/createPlayer/' + item.uid)}><EditIcon color="primary" /></Button></Grid>
                                <Grid item><Button onClick={() => deletePlayer(item.uid)}><DeleteIcon color="secondary" /></Button></Grid>
                            </Grid>
                        </Paper></ListItem>
                    ))}
                </List>
                <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => history.push('/createPlayer')}>
                    <AddIcon />
                </Fab>
            </div>
        }
    }

    return (
        getView()
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        flexGrow: 1,
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1.5),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',
    },
    container: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default Players;