import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

function SelectPlayerDraw(props) {
    const classes = useStyles();
    const { slot, round, placedPlayer, addPlacedPlayer, position, removePlacedPlayer } = props;
    const playersInTournament = JSON.parse(localStorage.getItem('playersInTournament'));
    //const playersInTournament = [{ name: 'mauron', firstname: 'jocelyn' }]
    const [selected, setSelected] = useState(false)
    const [player, setPlayer] = useState();
    const bye = { firstname: 'bye', name: '', uid: '' }

    useEffect(() => {
        var placed = placedPlayer.filter(e => e.round === round && e.slot === slot && e.position === position);
        if (placed.length > 0) {
            setPlayer(placed[0]);
            setSelected(true);
        }
    })

    useEffect(() => {
        var placed = placedPlayer.filter(e => e.round === round && e.slot === slot && e.position === position);
        //console.log(placed)
        console.log(placed)
        console.log(selected)
        if (placed.length > 0) {
            setPlayer(placed[0])
        }
    }, [placedPlayer])

    /*useEffect(() => {
        var placed = placedPlayer.filter(e => e.round === round && e.slot === slot && e.position === position);
        //console.log(placed)
        console.log('select')
        if (placed.length > 0) {
            setPlayer(placed[0])
        }

    }, [selected])*/

    const resetPlayer = () => {
        setSelected(false);
        removePlacedPlayer(player);
        setPlayer(undefined);
    }

    const handleSelectChange = event => {
        setSelected(true);
        var item = JSON.parse(event.target.value)
        var tmpItem = { ...item, round: round, slot: slot, position: position };
        addPlacedPlayer(tmpItem)
    }

    const getView = () => {
        if (selected && player !== undefined) {
            return <Grid container className={classes.container}>
                <Grid item xs><Typography>{player.name} {player.firstname}</Typography></Grid>
                <Grid item xs className={classes.deleteIcon}><Button onClick={() => resetPlayer()}><DeleteIcon /></Button></Grid>
            </Grid>
        } else {
            return <FormControl className={classes.formControl}>
                <InputLabel id="label" shrink="true">Sélectionner un joueur</InputLabel>
                <Select labelId="label" onChange={handleSelectChange}>
                    <MenuItem value={JSON.stringify(bye)}>BYE</MenuItem>
                    {playersInTournament.map((item, i) => (
                        <MenuItem value={JSON.stringify(item)}>{item.name} {item.firstname}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        }
    }

    return (
        getView()
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        flexGrow: 1,
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',

    },
    deleteIcon: {
        textAlign: 'right'
    },
    formControl: {
        display: 'flex',
        justifyContent: 'stretch',
        margin: theme.spacing(1)
    },
    container: {
        alignItems: 'center',
        padding: theme.spacing(1)
    }
}));

export default SelectPlayerDraw;