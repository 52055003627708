import React, { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UserContext } from '../context/UserContext';
import { makeStyles } from '@material-ui/core/styles';
import { app } from '../firebase/firebase';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
function Login() {
    const history = useHistory();
    const classes = useStyles();
    const [email, setUser] = useState();
    const [mdp, setMdp] = useState();
    const user = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [emailForgot, setEmailForgot] = useState();

    const login = () => {
        app.auth().signInWithEmailAndPassword(email, mdp).then((result) => {
            let id = result.user.uid;
            app.firestore().collection('players').doc(id).get().then(doc => {
                user.update(id, doc.data().role);
                history.push('/matchs')
            })
        }).catch((e) => {
            alert('Login incorrect')
            console.error(e);
        });
    }


    const handleClose = () => {
        setOpen(false);
    };

    const resetPasswordEmail = () => {
        if (emailForgot) {
            app.auth().sendPasswordResetEmail(emailForgot)
                .then(() => {
                    alert('Email envoyé !');
                    setOpen(false);
                })
                .catch(() => {
                    alert("Oups ! L'email n'est pas correct.")
                })
        }
    }

    const dialogView = () => {
        return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Mot de passe oublié</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Entrez votre adresse email pour réinitialiser votre mot de passe. Vous allez recevoir un lien dans votre boite mail.
                    Attention l'email doit être celui de votre compte utilisateur sinon vous ne pourrez pas changer de mot de passe.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email"
                    type="email"
                    fullWidth
                    onChange={(e) => setEmailForgot(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={resetPasswordEmail} color="primary">
                    Envoyer
                </Button>
            </DialogActions>
        </Dialog>
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.content}>Connectez-vous à votre compte.</Typography>
            <TextField label="email" type="email" className={classes.content} onChange={(e) => setUser(e.target.value)} />
            <TextField label="password" type="password" className={classes.content} onChange={(e) => setMdp(e.target.value)} />
            <Button variant="contained" color="primary" onClick={() => login()} className={classes.content}>Login</Button>
            <Button variant="text" color="primary" onClick={() => setOpen(true)} className={classes.content}>Mot de passe oublié ?</Button>
            {dialogView()}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        margin: theme.spacing(1)
    }
}));

export default Login;