import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Round from '../../components/round';
import Button from '@material-ui/core/Button';
import { app } from '../../firebase/firebase';
import { useParams } from "react-router-dom";
import { match } from 'assert';

function CreateDraw() {
    const history = useHistory();
    const playersInTournament = JSON.parse(localStorage.getItem('playersInTournament'));
    const [nbRound, setNbRound] = useState(0);
    let nb = playersInTournament.length;
    const [value, setValue] = useState(0);
    const [rounds, setRounds] = useState([])
    const [placedPlayer, setPlacedPlayer] = useState([]);
    let { tournamentId } = useParams();
    useEffect(() => {
        var tmpRound = 0;
        while (Math.pow(2, tmpRound + 1) < nb) {
            tmpRound++;
        }
        setNbRound(tmpRound)
    }, [])

    //Check if current player or opponent is bye
    const isBye = (item) => {
        if (item.firstname === 'bye') {
            return true;
        } else {
            var opponentPosition = item.position % 2 + 1;
            var foundOpponent = placedPlayer.filter(e => e.round === item.round && e.slot === item.slot && e.position === opponentPosition);
            if (foundOpponent.length > 0) {
                if (foundOpponent[0].firstname === 'bye') {
                    return true;
                }
            }
        }
        return false;
    }

    //get next slot when a player is automatically qualified
    const getNextSlot = (slot) => {
        if (slot < 2) {
            return 0;
        } else if (slot < 4) {
            return 1;
        } else {
            if (slot % 2 === 0) {
                return slot / 2;
            } else {
                return (slot - 1) / 2;
            }
        }
    }

    //select player
    const addPlacedPlayer = (item) => {
        var tmpPlacedPlayer = [...placedPlayer];
        var score = [0, 0, 0]
        if (isBye(item)) {
            if (item.firstname !== 'bye') {
                //add item is real player
                var position = 1
                if (item.slot % 2 !== 0) {
                    position = 2
                }
                var tmpItem = { ...item, position: position, round: item.round + 1, slot: getNextSlot(item.slot), score: score };
                tmpPlacedPlayer.push(tmpItem);
            } else {
                //add item is bye
                var opponentPosition = item.position % 2 + 1;
                var foundOpponent = placedPlayer.filter(e => e.round === item.round && e.slot === item.slot && e.position === opponentPosition);
                if (foundOpponent.length > 0) {
                    var position = 1
                    if (item.slot % 2 !== 0) {
                        position = 2
                    }
                    var tmpItem = { ...foundOpponent[0], position: position, round: item.round + 1, slot: getNextSlot(item.slot), score: score };
                    tmpPlacedPlayer.push(tmpItem);
                }
            }
        }
        tmpPlacedPlayer.push({ ...item, score: score });
        setPlacedPlayer(tmpPlacedPlayer);
    }

    //reset selected player
    const removePlacedPlayer = (item) => {
        var tmpPlaced = placedPlayer.filter(e => e !== item);
        setPlacedPlayer(tmpPlaced);
    }

    //create tabs menu of each round
    const getTabs = () => {
        var items = [];
        for (var i = 0; i <= nbRound; i++) {
            var nb = i + 1;
            items.push(<Tab label={"Tour " + nb} />)
        }
        return items;
    }

    //display list of match in each round
    const getRound = () => {
        var items = [];
        for (var i = 0; i <= nbRound; i++) {
            items.push(<Round value={value} index={i} nbRound={nbRound} placedPlayer={placedPlayer} addPlacedPlayer={addPlacedPlayer} removePlacedPlayer={removePlacedPlayer} />);
        }
        return items;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const createDraw = () => {
        //format placedPlayer correctly
        var formatMatchs = formatPlacedPlayer()
        console.log(formatMatchs)
        app.firestore().collection('draws').doc().set({
            refTournament: tournamentId,
            rounds: nbRound + 1,
            matchs: formatMatchs,
        }).then(res => {
            alert('Tournoi créé avec succès')
            history.push('/')
        })
            .catch(err => {
                console.error(err)
                alert(err.message)
            })
    }

    const formatPlacedPlayer = () => {
        var matchs = [];
        var rounds = nbRound;
        while (rounds >= 0) {
            var slot = 0;
            var round = nbRound - rounds
            for (var i = 0; i < Math.pow(2, rounds); i++) {
                var item = {
                    player1: {
                        info: { firstname: '', name: 'A définir', uid: '' }
                    },
                    player2: {
                        info: { firstname: '', name: 'A définir', uid: '' }
                    },
                    scorePlayer1: {
                        set1: 0,
                        set2: 0,
                        set3: 0
                    },
                    scorePlayer2: {
                        set1: 0,
                        set2: 0,
                        set3: 0
                    },
                    slot: 0,
                    round: 0,
                    state: 'toplay',
                }

                var player1 = placedPlayer.filter(el => el.round === round && el.slot === slot && el.position === 1);
                if (player1.length > 0) {
                    item.player1.info.firstname = player1[0].firstname
                    item.player1.info.name = player1[0].name
                    item.player1.info.uid = player1[0].uid
                }
                var player2 = placedPlayer.filter(el => el.round === round && el.slot === slot && el.position === 2);
                if (player2.length > 0) {
                    item.player2.info.firstname = player2[0].firstname
                    item.player2.info.name = player2[0].name
                    item.player2.info.uid = player2[0].uid
                }
                item.slot = slot;
                item.round = round
                matchs.push(item)
                slot++
            }
            round++
            rounds--;
        }
        return matchs
    }

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
            >
                {getTabs()}
            </Tabs>
            {getRound()}
            <Button variant="contained" color="primary" onClick={() => createDraw()}>Créer le tournoi</Button>
        </div>
    )
}

export default CreateDraw;