import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { app } from '../firebase/firebase';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

function Profile() {
    const history = useHistory();
    const classes = useStyles();
    const [user, setUser] = useState('')
    const [email, setEmail] = useState('');
    const [mdp, setMdp] = useState('');
    const [confirmMdp, setConfirmMdp] = useState('');

    useEffect(() => {
        app.auth().onAuthStateChanged(function (user) {
            if (user) {
                setUser(user)
                setEmail(user.email)
            } else {
                // No user is signed in.
            }
        });
    }, [])

    const update = () => {
        if(mdp !== confirmMdp){
            alert('Les mots de passe ne correspondent pas.')
        }else{
            user.updatePassword(mdp).then(res=>{
                alert('Mot de passe mis à jour !')
                history.replace('/logout')
            }).catch(err=>{
                alert('Impossible de mettre à jour le mot de passe ! \nLe mot de passe doit contenir 6 charactères au minimum.')
                console.error(err)
            })
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.content}>Votre compte</Typography>
            <TextField disabled value={email} label="email" type="Email" className={classes.content} onChange={(e) => setUser(e.target.value)} />
            <TextField value={mdp} label="Mot de passe" type="password" className={classes.content} onChange={(e) => setMdp(e.target.value)} />
            <TextField label="Confirmez le mot de passe" type="password" className={classes.content} onChange={(e) => setConfirmMdp(e.target.value)} />
            <Button variant="contained" color="primary" onClick={() => update()} className={classes.content}>Modifier mon mot de passe</Button>
        </div>
    )

}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        margin: theme.spacing(1)
    }
}));

export default Profile;