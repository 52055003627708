const firebaseConfig = {
    apiKey: "AIzaSyC418YSLnag5AmZuZ4ANk6xhG6MdVPxbxk",
    authDomain: "tcg-app-634b3.firebaseapp.com",
    databaseURL: "https://tcg-app-634b3.firebaseio.com",
    projectId: "tcg-app-634b3",
    storageBucket: "tcg-app-634b3.appspot.com",
    messagingSenderId: "280853459317",
    appId: "1:280853459317:web:2ef9c7d31cb35ab60e633c",
    measurementId: "G-WSZ3JNDHWH"
  };

  export default firebaseConfig;