import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { app } from '../firebase/firebase';
import { UserContext } from '../context/UserContext';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function ScoreDialog(props) {
    const user = useContext(UserContext);
    const classes = useStyles();
    const [scorePlayer1, setScore1] = useState([props.match.scorePlayer1.set1, props.match.scorePlayer1.set2, props.match.scorePlayer1.set3])
    const [scorePlayer2, setScore2] = useState([props.match.scorePlayer2.set1, props.match.scorePlayer2.set2, props.match.scorePlayer2.set3])
    const [oldGameWon1, setOldGameWon1] = useState(0)
    const [oldGameWon2, setOldGameWon2] = useState(0)
    const [oldSetWon1, setOldSetWon1] = useState(0)
    const [oldSetWon2, setOldSetWon2] = useState(0)
    const [oldMatchWon1, setOldMatchWon1] = useState(0)
    const [oldMatchWon2, setOldMatchWon2] = useState(0)
    const [wo, setWo] = useState(false);
    const [woPlayer1, setWoPlayer1] = useState(false);
    const [woPlayer2, setWoPlayer2] = useState(false);

    useEffect(() => {
        if (props.open === true && props.match.state === 'finish' && props.type === 'pool') {
            calculateOldClassement()
        }
    }, [props.open])

    const handleChangeScore1 = (event) => {
        var score = event.currentTarget.value
        var tmpScore = scorePlayer1;
        tmpScore[event.currentTarget.name] = Number(score);
        setScore1(tmpScore)
    }

    const handleChangeScore2 = (event) => {
        var score = event.currentTarget.value
        var tmpScore = scorePlayer2;
        tmpScore[event.currentTarget.name] = Number(score);
        setScore2(tmpScore)
    }

    const updateScore = () => {
        if (props.type === 'pool') {
            updatePoolScore()
        } else {
            //draw
            if (props.view === 'perso') {
                enterDrawScore()
            } else {
                updateDrawScore()
            }
        }
    }

    const updateDrawScore = () => {
        props.updateScore(scorePlayer1, scorePlayer2, props.match.round, props.match.slot);
        props.close()
    }

    const enterDrawScore = () => {
        app.firestore().collection('draws').where('refTournament', '==', props.match.refTournament).get().then(snapshot => {
            snapshot.forEach(doc => {
                var data = doc.data()
                var tmp = data.matchs
                for (var i = 0; i < tmp.length; i++) {
                    if (tmp[i].player1.info.uid === props.match.player1.info.uid && tmp[i].player2.info.uid === props.match.player2.info.uid) {
                        //player 1
                        tmp[i].scorePlayer1.set1 = scorePlayer1[0]
                        tmp[i].scorePlayer1.set2 = scorePlayer1[1]
                        tmp[i].scorePlayer1.set3 = scorePlayer1[2]
                        //player 2
                        tmp[i].scorePlayer2.set1 = scorePlayer2[0]
                        tmp[i].scorePlayer2.set2 = scorePlayer2[1]
                        tmp[i].scorePlayer2.set3 = scorePlayer2[2]

                        tmp[i].state = 'tovalidate'
                        tmp[i].userSetScore = user.uid
                    }

                }
                var item = { ...data, matchs: tmp }
                doc.ref.update(item).then(res => {
                    alert('Le score a bien été mis à jour.')
                    props.close()
                    props.reload()
                }).catch(err => {
                    alert('Oups: ' + err.message)
                    console.error(err)
                })
            })
        })
    }

    const updatePoolScore = () => {
        if (props.admin) {
            setScoreAdmin();
            return
        }
        app.firestore().collection('matches').doc(props.match.id).update({
            userSetScore: user.uid,
            scorePlayer1: {
                set1: scorePlayer1[0],
                set2: scorePlayer1[1],
                set3: scorePlayer1[2]
            },
            scorePlayer2: {
                set1: scorePlayer2[0],
                set2: scorePlayer2[1],
                set3: scorePlayer2[2]
            },
            state: 'tovalidate'
        }).then(res => {
            alert('Le score a bien été mis à jour.')
            props.close()
            props.reload()
        }).catch(err => {
            alert('Oups: ' + err.message)
            console.error(err)
        })
    }

    const calculateOldClassement = () => {
        console.log(scorePlayer1)
        console.log(scorePlayer2)
        var gamePlayer1 = scorePlayer1[0] + scorePlayer1[1] + scorePlayer1[2];
        var gamePlayer2 = scorePlayer2[0] + scorePlayer2[1] + scorePlayer2[2];
        var setPlayer1 = 0
        var setPlayer2 = 0
        var matchPlayer1 = 0
        var matchPlayer2 = 0
        if (scorePlayer1[0] > scorePlayer2[0]) {
            setPlayer1++;
        } else if (scorePlayer1[0] < scorePlayer2[0]) {
            setPlayer2++;
        }
        if (scorePlayer1[1] > scorePlayer2[1]) {
            setPlayer1++;
        } else if (scorePlayer1[1] < scorePlayer2[1]) {
            setPlayer2++;
        }
        if (scorePlayer1[2] > scorePlayer2[2]) {
            setPlayer1++;
        } else if (scorePlayer1[2] < scorePlayer2[2]) {
            setPlayer2++;
        }
        if (setPlayer1 > setPlayer2) {
            matchPlayer1++;
        } else if (setPlayer1 < setPlayer2) {
            matchPlayer2++;
        }
        setOldGameWon1(gamePlayer1);
        setOldGameWon2(gamePlayer2);
        setOldMatchWon1(matchPlayer1);
        setOldMatchWon2(matchPlayer2);
        setOldSetWon1(setPlayer1);
        setOldSetWon2(setPlayer2);

    }

    /*
    * Même code que dans matchs.js !! A modifier pour éviter ça...
    */
    const calculateClassement = (item) => {
        var gamePlayer1 = scorePlayer1[0] + scorePlayer1[1] + scorePlayer1[2];
        var gamePlayer2 = scorePlayer2[0] + scorePlayer2[1] + scorePlayer2[2];
        var setPlayer1 = item.player1.info.setWon
        var setPlayer2 = item.player2.info.setWon
        var matchPlayer1 = item.player1.info.matchWon
        var matchPlayer2 = item.player2.info.matchWon
        if (scorePlayer1[0] > scorePlayer2[0]) {
            setPlayer1++;
        } else if (scorePlayer1[0] < scorePlayer2[0]) {
            setPlayer2++;
        }
        if (scorePlayer1[1] > scorePlayer2[1]) {
            setPlayer1++;
        } else if (scorePlayer1[1] < scorePlayer2[1]) {
            setPlayer2++;
        }
        if (scorePlayer1[2] > scorePlayer2[2]) {
            setPlayer1++;
        } else if (scorePlayer1[2] < scorePlayer2[2]) {
            setPlayer2++;
        }
        if (woPlayer1) {
            matchPlayer1++;
        } else if (woPlayer2) {
            matchPlayer2++;
        } else if (setPlayer1 > setPlayer2) {
            matchPlayer1++;
        } else if (setPlayer1 < setPlayer2) {
            matchPlayer2++;
        }

        app.firestore().collection('pools').doc(item.refPool).get().then(doc => {
            var players = doc.data().players;
            console.log(players)
            players.map((val, index) => {
                if (val.uid === item.player1.info.uid) {
                    players[index].gameWon += gamePlayer1 - oldGameWon1;
                    players[index].setWon += setPlayer1 - oldSetWon1;
                    players[index].matchWon += matchPlayer1 - oldMatchWon1;
                }
                if (val.uid === item.player2.info.uid) {
                    players[index].gameWon += gamePlayer2 - oldGameWon2;
                    players[index].setWon += setPlayer2 - oldSetWon2;
                    players[index].matchWon += matchPlayer2 - oldMatchWon2;
                }
            })
            console.log('classement calculate ok')
            updateClassement(item.refPool, players)
        }).catch(err => {
            console.error(err)
        })
    }

    const updateClassement = (id, players) => {
        app.firestore().collection('pools').doc(id).update({
            players: players
        }).then(res => {
            console.log('classement update ok')
        }).catch(err => {
            console.error(err)
        })
    }

    //the administrator set the score, no need to validate it by another player
    const setScoreAdmin = () => {
        app.firestore().collection('matches').doc(props.match.id).update({
            woPlayer1: woPlayer1,
            woPlayer2: woPlayer2,
            scorePlayer1: {
                set1: scorePlayer1[0],
                set2: scorePlayer1[1],
                set3: scorePlayer1[2]
            },
            scorePlayer2: {
                set1: scorePlayer2[0],
                set2: scorePlayer2[1],
                set3: scorePlayer2[2]
            },
            state: 'finish'
        }).then(res => {
            alert('Le score a bien été mis à jour.')
            calculateClassement(props.match)
            props.close()
            props.reload()
        }).catch(err => {
            alert('Oups: ' + err.message)
            console.error(err)
        })
    }

    const handleChange = (event) => {
        if (event.target.value === props.match.player1) {
            setWoPlayer1(true);
            setWoPlayer2(false);
        } else if (event.target.value === props.match.player2) {
            setWoPlayer1(false);
            setWoPlayer2(true);
        } else {
            setWoPlayer1(false);
            setWoPlayer2(false);
        }

        setWo(event.target.value);
    };

    const getWoBox = () => {
        if (props.admin) {
            return <div className={classes.col}>Vainqueur par WO:<Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={wo}
                onChange={handleChange}
            >
                <MenuItem value={props.match.player1}>{props.match.player1.info.name} {props.match.player1.info.firstname}</MenuItem>
                <MenuItem value={props.match.player2}>{props.match.player2.info.name} {props.match.player2.info.firstname}</MenuItem>
            </Select></div>
        }
    }

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={props.open} maxWidth='xs' fullWidth='true'>
            <DialogTitle id="simple-dialog-title">Entrez le score de la rencontre</DialogTitle>
            <Paper className={classes.paper}>
                <Grid container className={classes.container}>
                    <Grid item xs={12} sm={6}>
                        <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.player1.info.name} {props.match.player1.info.firstname}</Box></Typography>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            type='number'
                            className={classes.txtScore}
                            defaultValue={props.match.scorePlayer1.set1}
                            margin="normal"
                            variant="outlined"
                            name={0}
                            onChange={event => handleChangeScore1(event)}
                            inputProps={{ min: 0, max: 7 }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            type='number'
                            className={classes.txtScore}
                            defaultValue={props.match.scorePlayer1.set2}
                            margin="normal"
                            variant="outlined"
                            name={1}
                            onChange={event => handleChangeScore1(event)}
                            inputProps={{ min: 0, max: 7 }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            type='number'
                            className={classes.txtScore}
                            defaultValue={props.match.scorePlayer1.set3}
                            margin="normal"
                            variant="outlined"
                            name={2}
                            onChange={event => handleChangeScore1(event)}
                            inputProps={{ min: 0, max: 7 }}
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.container}>
                    <Grid item xs={12} sm={6}>
                        <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.player2.info.name} {props.match.player2.info.firstname}</Box></Typography>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            type='number'
                            className={classes.txtScore}
                            defaultValue={props.match.scorePlayer2.set1}
                            margin="normal"
                            variant="outlined"
                            name={0}
                            onChange={event => handleChangeScore2(event)}
                            inputProps={{ min: 0, max: 7 }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            type='number'
                            className={classes.txtScore}
                            defaultValue={props.match.scorePlayer2.set2}
                            margin="normal"
                            variant="outlined"
                            name={1}
                            onChange={event => handleChangeScore2(event)}
                            inputProps={{ min: 0, max: 7 }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            type='number'
                            className={classes.txtScore}
                            defaultValue={props.match.scorePlayer2.set3}
                            margin="normal"
                            variant="outlined"
                            name={2}
                            onChange={event => handleChangeScore2(event)}
                            inputProps={{ min: 0, max: 7 }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <div className={classes.wo}>
                {getWoBox()}
            </div>
            <Grid container className={classes.containerBtn}>
                <Grid item className={classes.btn}>
                    <Button variant="outlined" color="primary" onClick={() => { setWo(false); props.close() }}>Annuler</Button>
                </Grid>
                <Grid item className={classes.btn}>
                    <Button variant="contained" color="primary" onClick={() => updateScore()}>Valider</Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        flexGrow: 1,
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',

    },
    container: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerBtn: {
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3)
    },
    btn: {
        margin: theme.spacing(1),
        textAlign: 'end'
    },
    txtScore: {
        margin: theme.spacing(0.5)
    },
    wo: {
        display: 'flex',
        margin: theme.spacing(1),
        flexDirection: 'column'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default ScoreDialog;