import React from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import SelectPlayerDraw from './selectPlayerDraw.js';

function MatchSlot(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <SelectPlayerDraw  round={props.round} slot={props.slot} placedPlayer={props.placedPlayer} addPlacedPlayer={props.addPlacedPlayer} position={1} removePlacedPlayer={props.removePlacedPlayer}/>
            <Divider />
            <SelectPlayerDraw round={props.round} slot={props.slot} placedPlayer={props.placedPlayer} addPlacedPlayer={props.addPlacedPlayer} position={2} removePlacedPlayer={props.removePlacedPlayer}/>
        </Paper>
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        flexGrow: 1,
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',

    },
    formControl: {
        display: 'flex',
        justifyContent: 'stretch',
        margin: theme.spacing(1)
    },
    container: {
        alignItems: 'center'
    }
}));

export default MatchSlot;