import React, { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import SelectPlayer from '../../components/selectPlayer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/grid';
import { app } from '../../firebase/firebase';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

function CreateTournament() {
    const classes = useStyles();
    const history = useHistory();
    //format Date:'2014-08-18T21:11:54'
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [name, setName] = useState()
    const user = useContext(UserContext);

    function formatDate(string) {
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    const createTournament = () => {
        app.firestore().collection('tournaments').add({
            refAdmin: user.uid,
            name: name,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            type: 'pool'
        }).then((res) => {
            alert('Tournoi créé vous pouvez désormais ajouter les groupes avec les joueurs.');
            history.push('/createPool/' + res.id);
        }).catch(err => {
            alert('Oups: ' + err.message)
            console.error(err)
        })
    }

    const createTournamentDraw = () => {
        app.firestore().collection('tournaments').add({
            refAdmin: user.uid,
            name: name,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            type: 'draw'
        }).then((res) => {
            alert('Tournoi créé vous pouvez désormais placer les joueurs dans le tableau');
            history.push('/createDraw/' + res.id);
        }).catch(err => {
            alert('Oups: ' + err.message)
            console.error(err)
        })
    }

    return (
        <div className={classes.container}>
            <TextField id="outlined-basic" label="Nom du tournoi" variant="outlined" onChange={event => setName(event.currentTarget.value)} />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Début du tournoi"
                    autoOk={true}
                    value={startDate}
                    onChange={date => setStartDate(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Fin du tournoi"
                    autoOk={true}
                    value={endDate}
                    onChange={date => setEndDate(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <SelectPlayer />
            <Grid container>
                <Grid item xs className={classes.btn}>
                    <Button variant="contained" color="primary" onClick={() => createTournament()}>
                        Tournoi de groupes
                    </Button>
                </Grid>
                <Grid item xs className={classes.btn}>
                    <Button variant="contained" color="primary" onClick={() => createTournamentDraw()}>
                        Tableau éliminatoire
                    </Button>
                </Grid>
            </Grid>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(1),
        alignItems: 'stretch',
    },
    btn: {
        flexDirection: 'column',
        display: 'flex',
        margin: theme.spacing(1)
    }
}));

export default CreateTournament;