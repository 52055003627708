import React, { useState, useEffect, useContext } from 'react';
import Tournament from '../components/tournament';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { app } from '../firebase/firebase';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

function Tournaments() {
    const classes = useStyles();
    const history = useHistory();
    const [tournaments, setTournaments] = useState([])
    const user = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTournaments()
    }, [])

    const getTournaments = () => {
        app.firestore().collection('tournaments').get().then(res => {
            var tmpTournaments = []
            res.forEach(doc => {
                var tmp = doc.data();
                tmp = { ...tmp, id: doc.id };
                tmpTournaments.push(tmp);
            })
            setTournaments(tmpTournaments);
            setLoading(false);
        }).catch(err => {
            console.error(err)
        })
    }

    const getFab = () => {
        if (user.role === 'Responsable' || user.role === 'Administrateur') {
            return <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => history.push('/createTournament')}>
                <AddIcon />
            </Fab>
        }
    }

    const viewEmpty = () => {
        if (tournaments.length === 0) {
            return <Typography variant="h5" component="h5">Aucun tournoi actuellement.</Typography>
        }
    }

    const getView = () => {
        if (loading) {
            return <CircularProgress />
        } else {
            return <div className={classes.root}>
                {viewEmpty()}
                {tournaments.map((item, index) => (
                    <Tournament type={item.type} name={item.name} dateDebut={item.startDate} dateFin={item.endDate} id={item.id} refAdmin={item.refAdmin} />
                ))}
                {getFab()}
            </div>
        }
    }

    return (
        getView()
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default Tournaments;