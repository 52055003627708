import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MatchSlot from './matchSlot';


//round component on the administration side when a user wants to create a draw tournament
function Round(props) {
    const { value, index, nbRound, placedPlayer, addPlacedPlayer, removePlacedPlayer } = props;

    const getListMatch = () => {
        var items = [];
        var round = nbRound - index; //reverse to get the number of matchs
        for (var i = 0; i < Math.pow(2, round); i++) {
            items.push(<MatchSlot round={index} slot={i} placedPlayer={placedPlayer} addPlacedPlayer={addPlacedPlayer} removePlacedPlayer={removePlacedPlayer} />)
        }
        return items;
    }

    return (
        <div hidden={value !== index}>{getListMatch()}</div>
    )
}

export default Round;