import React, { useState } from 'react';
import Paper from '@material-ui/core/paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '@material-ui/icons/Remove';
import MenuItem from '@material-ui/core/MenuItem';
import { useParams } from "react-router-dom";
import { app } from '../../firebase/firebase';
import { useHistory } from 'react-router-dom';


function CreatePool() {
    const history = useHistory();
    const [pools, setPools] = useState([{ name: 'groupe 1', players: [] }]);
    const playersInTournament = JSON.parse(localStorage.getItem('playersInTournament'));
    const classes = useStyles();
    let { tournamentId } = useParams();
    let id = pools.length + 1;
    const [selectedPlayer, SetSelectedPlayer] = useState();

    const handleSelectChange = event => {
        SetSelectedPlayer(JSON.parse(event.target.value));
    }
    const addPlayerToPool = (index) => {
        console.log(index)
        let newPools = [...pools]
        newPools[index].players = newPools[index].players.concat(selectedPlayer);
        console.log(newPools)
        setPools(newPools);
    }

    const removePlayerToPool = (poolIndex, playerIndex) => {
        let newPools = [...pools]
        newPools[poolIndex].players = pools[poolIndex].players.filter((e, i) => i !== playerIndex);
        setPools(newPools);
    }

    const createPools = () => {
        pools.map((item, index) => {
            app.firestore().collection('pools').add({
                refTournament: tournamentId,
                name: item.name,
                players: item.players
            }).then(res => {
                console.log('pool created')
                createMatchs(res.id, item.players)
            }).catch(err => {
                alert('Oups: ' + err.message)
            })
        })
    }

    //match state: [toplay, tovalidate, finish]
    const createMatchs = (poolId, poolPlayers) => {
        for (var i = 0; i < poolPlayers.length; i++) {
            var player = poolPlayers[i];
            for (var j = i + 1; j < poolPlayers.length; j++) {
                app.firestore().collection('matches').doc().set({
                    refPool: poolId,
                    player1: { info: player},
                    scorePlayer1: {set1: 0, set2: 0, set3: 0 },
                    player2: { info: poolPlayers[j]},
                    scorePlayer2: {set1: 0, set2: 0, set3: 0 },
                    state: 'toplay'
                }).then(res => {
                    console.log('match created')
                    history.replace('/')
                }).catch(err => {
                    console.error(err)
                    alert('Oups: ' + err.message)
                })
            }
        }
    }

    return (
        <div>
            {pools.map((pool, indexPool) => (
                <Paper className={classes.paper}>
                    <Grid container className={classes.container}>
                        <Grid item xs={3}><Typography>{pool.name}</Typography></Grid>
                        <Grid item xs>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="label" shrink="true">Sélectionner un joueur</InputLabel>
                                <Select labelId="label" onChange={handleSelectChange}>{playersInTournament.map((item, i) => (
                                    <MenuItem value={JSON.stringify(item)}>{item.name} {item.firstname}</MenuItem>
                                ))}</Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} className={classes.btnAddRem}><Button onClick={() => addPlayerToPool(indexPool)}><AddIcon /></Button></Grid>
                    </Grid>
                    <Divider />
                    <List>
                        {pool.players.map((player, index) => (
                            <Grid container className={classes.container}>
                                <Grid item xs>
                                    <ListItem><Typography>{player.name} {player.firstname}</Typography></ListItem>
                                </Grid>
                                <Grid item xs={2} className={classes.btnAddRem}>
                                    <Button onClick={() => removePlayerToPool(indexPool, index)}><RemoveIcon /></Button>
                                </Grid>
                            </Grid>

                        ))}
                    </List>
                </Paper>
            ))}
            <Grid container className={classes.container}>
                <Grid item >
                    <Button className={classes.btn} variant="contained" color="primary" onClick={() => setPools(pools.concat({ name: 'groupe ' + id, players: [] }))}><AddIcon /></Button>
                    <Button className={classes.btn} variant="contained" color="secondary" onClick={() => setPools(pools.filter((_, i) => i !== pools.length - 1))}><RemoveIcon /></Button>
                </Grid>
                <Grid item className={classes.btn}>
                    <Button variant="contained" color="primary" onClick={() => createPools()}>Créer les groupes</Button>
                </Grid>
            </Grid>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',
        marginBottom: theme.spacing(2)

    },
    container: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    formControl: {
        display: 'flex',
        justifyContent: 'stretch'
    },
    btnAddRem: {
        textAlign: 'end',
    },
    btn: {
        margin: theme.spacing(1)
    }
}));

export default CreatePool;