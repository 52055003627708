import React from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Link from '@material-ui/core/Link';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';

function Tournament(props) {
    const classes = useStyles();
    const history = useHistory();

    const navigateTournament = () => {
        if (props.type === 'pool') {
            history.push('/pools/' + props.name + '/' + props.id + '/' + props.refAdmin)
        } else {
            history.push('/draw/' + props.name + '/' + props.id + '/' + props.refAdmin)
        }
    }

    return (
        <Card className={classes.root}>
            <Button className={classes.btn} onClick={() => navigateTournament()}>
                <div className={classes.row}>
                    <Grid container className={classes.container}>
                        <Grid item xs={12} sm={4} className={classes.paper}>
                            <Typography className={classes.bold} noWrap={false}>{props.name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.paper}>
                            <Typography noWrap={true}>{props.dateDebut}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.paper}>
                            <Typography noWrap={true}>{props.dateFin}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs className={classes.chevron}>
                        <ChevronRightIcon />
                    </Grid>
                </div>
            </Button>
        </Card>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    btn: {
        width: '100%'
    },
    container: {
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left'
    },
    paper: {
        color: theme.palette.text.primary,
    },
    chevron: {
        color: theme.palette.text.primary,
        textAlign: 'right'
    },
    row:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    bold:{
        fontWeight: '700'
    }
}));

export default Tournament;