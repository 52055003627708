import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function Match(props) {
    const classes = useStyles();
    let { id } = useParams();
    const [matchs, setMatchs] = useState([]);

    const getWoPlayer1 = () => {
        if (props.match.woPlayer1) {
            return <Typography component={'span'}><Box fontSize={11} fontWeight="fontWeightBold" m={1}>Vainqueur par WO</Box></Typography>
        } else {
            return null
        }
    }

    const getWoPlayer2 = () => {
        if (props.match.woPlayer2) {
            return <Typography component={'span'}><Box fontSize={11} fontWeight="fontWeightBold" m={1}>Vainqueur par WO</Box></Typography>
        } else {
            return null
        }
    }

    return (
        <Paper className={classes.paper}>
            <Grid container className={classes.container}>
                <Grid item xs={9}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.player1.info.name} {props.match.player1.info.firstname}</Box></Typography>
                    {getWoPlayer1()}
                </Grid>
                <Grid item xs={1}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.scorePlayer1.set1}</Box></Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.scorePlayer1.set2}</Box></Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}> {props.match.scorePlayer1.set3}</Box></Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item xs={9}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.player2.info.name} {props.match.player2.info.firstname}</Box></Typography>
                    {getWoPlayer2()}
                </Grid>
                <Grid item xs={1}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.scorePlayer2.set1}</Box></Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.scorePlayer2.set2}</Box></Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography component={'span'}><Box fontWeight="fontWeightRegular" m={1}>{props.match.scorePlayer2.set3}</Box></Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    container: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    paper: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',

    }
}));

export default Match;