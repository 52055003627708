import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/paper';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PlayerCell from '../components/playerCell';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { app } from '../firebase/firebase';

function SelectPlayer() {
    const classes = useStyles();
    const [players, setPlayers] = useState([])
    const [search, setSearch] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        getPlayers();
    }, [])

    useEffect(() => {
        setOptions(players);
    }, [players])

    useEffect(() => {
        if (search.length) {
            const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
            setOptions(players.filter(option =>
                option.name.match(searchPattern) || option.firstname.match(searchPattern)
            ));
        } else {
            setOptions(players);
        }
    }, [search])

    const getPlayers = () => {
        app.firestore().collection('players').get().then(snapshot => {
            var tmpPlayers = [];
            var i = 0;
            snapshot.forEach(doc => {
                console.log(doc.data())
                var tmp = doc.data();
                tmp = { ...tmp, uid: doc.id, checked: 0, key: i, matchWon: 0, setWon: 0, gameWon: 0};
                tmpPlayers.push(tmp);
                i++;
            })
            setPlayers(tmpPlayers);
        })
    }

    /* onChange for the checkbox, has to do this way because of the search bar. Otherwise it is not the correct selected player. */
    const SelectPlayer = (player) => {
        let newArray = [...players]
        let index;
        players.map((item, i) => {
            if (item.key === player.key) {
                index = i;
            }
        }
        )
        if (player.checked) {
            newArray[index] = { ...player, checked: 0 };
        } else {
            newArray[index] = { ...player, checked: 1 };
        }
        setPlayers(newArray);
    }

    useEffect(() => {
        let playersInTournament = players.filter((p) => p.checked === 1)
        localStorage.setItem('playersInTournament', JSON.stringify(playersInTournament))
    }, [players])

    return (
        <Paper className={classes.paper}>
            <TextField label="Chercher un joueur"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                onChange={(e) => setSearch(e.target.value.split(' '))} />
            <List className={classes.list}>

                {options.map((item, index) => (
                    <ListItem><PlayerCell player={item} action={SelectPlayer} /> </ListItem>
                ))}
            </List>
        </Paper>
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        flexGrow: 1,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    list: {
        maxHeight: 400,
        overflow: 'auto'
    }
}));

export default SelectPlayer;