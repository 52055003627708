import React, { useContext, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import logo from '../assets/logo-tcg.jpg';
import { Link } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import PeopleIcon from '@material-ui/icons/People';
import { UserContext } from '../context/UserContext';
import UserIcon from '@material-ui/icons/Person';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        backgroundColor: '#003454'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    img: {
        width: '60%',
        padding: '1em',
    },
    link: {
        textDecoration: 'none',
        color: 'black'
    },
    leftSide: {
        textAlign: 'center'
    }
}));

function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const user = useContext(UserContext);

    const getMenuUser = () => {
        if (user.uid === '') {
            return [{ title: 'Tournois', icon: <ListIcon />, route: '/' }]
        } else {
            if (user.role === 'Administrateur') {
                return [{ title: 'Tournois', icon: <ListIcon />, route: '/' }, { title: 'Mes matchs', icon: <SportsTennisIcon />, route: '/matchs' }, { title: 'Joueurs', icon: <PeopleIcon />, route: '/players' }]
            } else {
                return [{ title: 'Tournois', icon: <ListIcon />, route: '/' }, { title: 'Mes matchs', icon: <SportsTennisIcon />, route: '/matchs' }]
            }
        }
    }

    const getLoginMenu = () => {
        if (user.uid === '') {
            return [{ title: 'Connexion', icon: '', route: '/login' }]
        } else {
            return [{ title: 'Mon compte', icon: <UserIcon />, route: '/profile' }, { title: 'Déconnexion', icon: '', route: '/logout' }]
        }
    }


    const drawer = (
        <div className={classes.leftSide}>
            <img src={logo} className={classes.img} />
            <Divider />
            <List>
                {getMenuUser().map((item, index) => (
                    <Link to={item.route} className={classes.link} onClick={() => setMobileOpen(false)}>
                        <ListItem button key={item.title}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    </Link>
                ))}
            </List>
            <Divider />
            <List>
                {getLoginMenu().map((item, index) => (
                    <Link to={item.route} className={classes.link} onClick={() => setMobileOpen(false)}>
                        <ListItem button key="connexion">
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    );

    return (
        <div>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        TC Gletterens
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}

export default ResponsiveDrawer;
