import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Match from '../components/match';
import { UserContext } from '../context/UserContext';
import ScoreDialog from '../components/ScoreDialog';
import { makeStyles } from '@material-ui/core/styles';
import { app } from '../firebase/firebase';


//round displaying every matchs of this round
function RoundInfo(props) {
    const classes = useStyles();
    const { value, index, round, tournamentID } = props;
    const [matchsRound, setMatchsRound] = useState([]) //matchs for this round
    const user = useContext(UserContext);
    const [isAdmin, setAdmin] = useState(false)
    const [matchs, setMatchs] = useState(props.matchs)

    useEffect(() => {
        console.log(user.role)
        //var tmp = matchs.filter(el => el.round === round);
        isTournamentAdmin()
        //setMatchsRound(tmp)
    }, [])

    useEffect(() => {
        var tmp = matchs.filter(el => el.round === round);
        setMatchsRound(tmp)
    }, [matchs])

    const isTournamentAdmin = () => {
        if (user.role === 'Administrateur') {
            setAdmin(true)
        } else if (user.role === 'Responsable') {
            app.firestore().collection('tournaments').doc(tournamentID).get().then(doc => {
                if (doc.data().refAdmin === user.uid) {
                    setAdmin(true)
                }
            }).catch(err => {
                console.error(err)
            })
        }
    }

    const openDialog = (index) => {
        let newMatchs = [...matchsRound];
        newMatchs[index].open = true;
        setMatchsRound(newMatchs);
    }

    const closeDialog = (index) => {
        let newMatchs = [...matchsRound];
        newMatchs[index].open = false;
        setMatchsRound(newMatchs);
    }

    const editScore = (index) => {
        openDialog(index);
    }

    const updateScore = (score1, score2, round, slot) => {
        var tmp = [...matchs]
        var scorePlayer1 = {
            set1: score1[0],
            set2: score1[1],
            set3: score1[2]
        }
        var scorePlayer2 = {
            set1: score2[0],
            set2: score2[1],
            set3: score2[2]
        }
        var setPlayer1 = 0;
        var setPlayer2 = 0;
        if (score1[0] > score2[0]) {
            setPlayer1++;
        } else if (score1[0] < score2[0]) {
            setPlayer2++;
        }
        if (score1[1] > score2[1]) {
            setPlayer1++;
        } else if (score1[1] < score2[1]) {
            setPlayer2++;
        }
        if (score1[2] > score2[2]) {
            setPlayer1++;
        } else if (score1[2] < score2[2]) {
            setPlayer2++;
        }
        var player;
        for (var i = 0; i < tmp.length; i++) {
            if (tmp[i].round === round && tmp[i].slot === slot) {
                console.log('trouvé')
                tmp[i].scorePlayer1 = scorePlayer1
                tmp[i].scorePlayer2 = scorePlayer2
                if (score1[0] === 'W.O.') {
                    player = tmp[i].player1
                } else if (score2[0] === 'W.O.') {
                    player = tmp[i].player2
                } else {
                    if (setPlayer1 > setPlayer2) {
                        //player1 won
                        player = tmp[i].player1
                    } else {
                        //player2 won
                        player = tmp[i].player2
                    }
                }

            }
        }
        qualifyPlayer(player, round + 1, getNextSlot(slot), tmp, slot % 2)

    }

    const qualifyPlayer = (player, round, slot, tmp, position) => {
        for (var i = 0; i < tmp.length; i++) {
            if (tmp[i].round === round && tmp[i].slot === slot) {
                if (position === 0) {
                    tmp[i].player1 = player
                } else {
                    tmp[i].player2 = player
                }
            }
        }
        app.firestore().collection('draws').where('refTournament', '==', tournamentID).get().then(snapshot => {
            snapshot.forEach(doc => {
                doc.ref.update({ ...doc.data(), matchs: tmp })
                alert('Score modifié avec succès !')
                //console.log(doc)
                //setDraw(doc.data())
            });
        }).catch(err => {
            console.log(err)
        })
        setMatchs(tmp)
    }

    const getNextSlot = (slot) => {
        if (slot < 2) {
            return 0;
        } else if (slot < 4) {
            return 1;
        } else {
            if (slot % 2 === 0) {
                return slot / 2;
            } else {
                return (slot - 1) / 2;
            }
        }
    }

    const getEditBtn = (index) => {
        if (user.role === 'Administrateur' || isAdmin) {
            return <Button className={classes.editBtn} onClick={() => editScore(index)}>Edit</Button>
        }
    }

    return (
        <div hidden={value !== index} className={classes.container}>
            {matchsRound.map((item, i) =>
                <div>
                    <ScoreDialog index={i} open={item.open} close={() => closeDialog(i)} match={item} admin={isAdmin} type='draw' updateScore={updateScore} />
                    <Match match={item} />
                    {getEditBtn(i)}
                </div>)}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    container: {
        marginTop: 20
    },
    paper: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        backgroundColor: '#f1f1f1',

    },
    editBtn: {
        marginBottom: theme.spacing(2),
    }
}));

export default RoundInfo;