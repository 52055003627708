import React, { useState, useContext, useEffect } from 'react';
import Match from '../components/match';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ScoreDialog from '../components/ScoreDialog';
import { app } from '../firebase/firebase';
import { UserContext } from '../context/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

function Matchs() {
    const classes = useStyles();
    const user = useContext(UserContext);
    /* STATE: toplay, finish, tovalidate*/
    const [playerMatchs, setMatchs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tournaments, setTournaments] = useState([]);
    const [playerMatchsDraw, setPlayerMatchsDraw] = useState([]);

    useEffect(() => {
        getMatch()
    }, [])

    const getMatch = () => {
        var matchs = [];
        //look into field player1
        app.firestore().collection('matches').where('player1.info.uid', '==', user.uid).get().then(snapshot => {
            snapshot.forEach(doc => {
                var tmpData = doc.data()
                tmpData = { ...tmpData, id: doc.id, type: 'pool' }
                matchs.push(tmpData)
            })

            //look into field player2
            app.firestore().collection('matches').where('player2.info.uid', '==', user.uid).get().then(snapshot => {
                snapshot.forEach(doc => {
                    var tmpData = doc.data();
                    tmpData = { ...tmpData, id: doc.id, type: 'pool' };
                    matchs.push(tmpData);
                })
                //get draw matchs
                getMatchDraw(matchs)

            }).catch(err => {
                console.error(err)
            })
        }).catch(err => {
            console.error(err)
        })
    }

    const getMatchDraw = (matchs) => {
        app.firestore().collection('draws').get().then(snapshot => {
            snapshot.forEach(doc => {
                var data = doc.data()
                var playerSlots = data.matchs.filter(el => el.player1.info.uid === user.uid || el.player2.info.uid === user.uid)
                if (playerSlots.length > 0) {
                    playerSlots.forEach(el => matchs.push({ ...el, refTournament: data.refTournament, type: 'draw' }))
                }
            })
            console.log(matchs)
            setMatchs(matchs)
            setLoading(false);
        })
    }

    const openDialog = (index) => {
        let newMatchs = [...playerMatchs];
        newMatchs[index].open = true;
        setMatchs(newMatchs);
    }

    const closeDialog = (index) => {
        let newMatchs = [...playerMatchs];
        newMatchs[index].open = false;
        setMatchs(newMatchs);
    }

    const validateScore = (item, index) => {
        if (item.type === 'draw') {
            app.firestore().collection('draws').where('refTournament', '==', item.refTournament).get().then(snapshot => {
                snapshot.forEach(doc => {
                    var data = doc.data()
                    var tmp = data.matchs
                    for (var i = 0; i < tmp.length; i++) {
                        if (tmp[i].player1.info.uid === item.player1.info.uid && tmp[i].player2.info.uid === item.player2.info.uid) {
                            tmp[i].state = 'finish'
                            updateScore(item.scorePlayer1, item.scorePlayer2, item.round, item.slot, item.player1, item.player2, item.refTournament)
                        }
                    }
                    var match = { ...data, matchs: tmp }
                    doc.ref.update(match).then(res => {
                        //alert('Le score a bien été validé.')
                        closeDialog(index)
                        //getMatch()
                    }).catch(err => {
                        alert('Oups: ' + err.message)
                        console.error(err)
                    })
                })
            })
        } else {
            app.firestore().collection('matches').doc(item.id).update({
                state: 'finish'
            }).then(res => {
                alert('Le score a bien été validé.')
                getMatch()
                calculateClassement(item)
            }).catch(err => {
                alert('Oups: ' + err.message)
                console.error(err)
            })
        }

    }

    const updateScore = (score1, score2, round, slot, player1, player2, idTournament) => {
        var setPlayer1 = 0;
        var setPlayer2 = 0;
        if (score1.set1 > score2.set2) {
            setPlayer1++;
        } else if (score1.set1 < score2.set2) {
            setPlayer2++;
        }
        if (score1.set2 > score2.set2) {
            setPlayer1++;
        } else if (score1.set2 < score2.set2) {
            setPlayer2++;
        }
        if (score1.set3 > score2.set3) {
            setPlayer1++;
        } else if (score1.set3 < score2.set3) {
            setPlayer2++;
        }
        if (setPlayer1 > setPlayer2) {
            qualifyPlayer(player1, round + 1, getNextSlot(slot), slot % 2, idTournament)
        } else {
            qualifyPlayer(player2, round + 1, getNextSlot(slot), slot % 2, idTournament)
        }

    }

    const qualifyPlayer = (player, round, slot, position, idTournament) => {
        app.firestore().collection('draws').where('refTournament', '==', idTournament).get().then(snapshot => {
            snapshot.forEach(doc => {
                var data = doc.data()
                var tmp = data.matchs
                for (var i = 0; i < tmp.length; i++) {
                    if (tmp[i].slot === slot && tmp[i].round === round) {
                        if (position === 0) {
                            tmp[i].player1 = player
                        } else {
                            tmp[i].player2 = player
                        }
                    }
                }
                var match = { ...data, matchs: tmp }
                doc.ref.update(match).then(res => {
                    alert('Le score a bien été validé.')
                    getMatch()
                }).catch(err => {
                    alert('Oups: ' + err.message)
                    console.error(err)
                })
            })
        }).catch(err => {
            console.log(err)
        })
    }

    const getNextSlot = (slot) => {
        if (slot < 2) {
            return 0;
        } else if (slot < 4) {
            return 1;
        } else {
            if (slot % 2 === 0) {
                return slot / 2;
            } else {
                return (slot - 1) / 2;
            }
        }
    }

    const calculateClassement = (item) => {
        var gamePlayer1 = item.scorePlayer1.set1 + item.scorePlayer1.set2 + item.scorePlayer1.set3
        var gamePlayer2 = item.scorePlayer2.set1 + item.scorePlayer2.set2 + item.scorePlayer2.set3
        var setPlayer1 = item.player1.info.setWon
        var setPlayer2 = item.player2.info.setWon
        var matchPlayer1 = item.player1.info.matchWon
        var matchPlayer2 = item.player2.info.matchWon

        if (item.scorePlayer1.set1 > item.scorePlayer2.set1) {
            setPlayer1++;
        } else if (item.scorePlayer1.set1 < item.scorePlayer2.set1) {
            setPlayer2++;
        }
        if (item.scorePlayer1.set2 > item.scorePlayer2.set2) {
            setPlayer1++;
        } else if (item.scorePlayer1.set2 < item.scorePlayer2.set2) {
            setPlayer2++;
        }
        if (item.scorePlayer1.set3 > item.scorePlayer2.set3) {
            setPlayer1++;
        } else if (item.scorePlayer1.set3 < item.scorePlayer2.set3) {
            setPlayer2++;
        }
        if (setPlayer1 > setPlayer2) {
            matchPlayer1++;
        } else if (setPlayer1 < setPlayer2) {
            matchPlayer2++;
        }

        app.firestore().collection('pools').doc(item.refPool).get().then(doc => {
            var players = doc.data().players;
            console.log(players)
            players.map((val, index) => {
                if (val.uid === item.player1.info.uid) {
                    players[index].gameWon += gamePlayer1;
                    players[index].setWon += setPlayer1;
                    players[index].matchWon += matchPlayer1;
                }
                if (val.uid === item.player2.info.uid) {
                    players[index].gameWon += gamePlayer2;
                    players[index].setWon += setPlayer2;
                    players[index].matchWon += matchPlayer2;
                }
            })
            console.log('classement calculate ok')
            updateClassement(item.refPool, players)
        }).catch(err => {
            console.error(err)
        })
    }

    const updateClassement = (id, players) => {
        app.firestore().collection('pools').doc(id).update({
            players: players
        }).then(res => {
            console.log('classement update ok')
        }).catch(err => {
            console.error(err)
        })
    }


    const btnGrp = (item, index) => {
        if (item.state === 'toplay') {
            return <Grid container className={classes.container}>
                <Grid item className={classes.btn}>
                    <Button variant="contained" color="primary" onClick={() => openDialog(index)}>Entrer le score</Button>
                </Grid>
            </Grid>
        } else if (item.state === 'tovalidate' && item.userSetScore !== user.uid) {
            return <div>
                <Grid container className={classes.container}>
                    <Grid item className={classes.btn}>
                        <Button variant="outlined" color="primary" onClick={() => openDialog(index)}>Modifier</Button>
                    </Grid>
                    <Grid item className={classes.btn}>
                        <Button variant="contained" color="primary" onClick={() => validateScore(item, index)}>Valider</Button>
                    </Grid>
                </Grid>
            </div>
        } else if (item.state === 'tovalidate' && item.userSetScore === user.uid) {
            return <Grid container className={classes.container}>
                <Grid item className={classes.btn}>
                    <Typography>En attende de validation...</Typography>
                </Grid>
            </Grid>
        }
    }

    const emptyMatch = () => {
        if (playerMatchs.length == 0) {
            return <Typography variant="h5" component="h5">Tu n'as pas de match actuellement.</Typography>
        }
    }

    const getTournamentName = (item) => {
        if (item.type === 'pool') {
            var id = item.refPool
            var tmp = [...tournaments];
            var tmpFound = tournaments.filter(el => el.id === id);
            if (tmpFound.length > 0) {
                return tmpFound[0].name
            } else {
                var name = ''
                app.firestore().collection('pools').doc(id).get().then(doc => {
                    if (doc.exists) {
                        var refTournament = doc.data().refTournament
                        app.firestore().collection('tournaments').doc(refTournament).get().then(doc => {
                            if (doc.exists) {
                                name = doc.data().name
                                tmp.push({ id: id, name: name })
                                setTournaments(tmp)
                            }
                        })
                    }
                })
            }
        } else {
            var id = item.refTournament
            var tmp = [...tournaments];
            var tmpFound = tournaments.filter(el => el.id === id);
            if (tmpFound.length > 0) {
                return tmpFound[0].name
            } else {
                var name = ''
                app.firestore().collection('tournaments').doc(id).get().then(doc => {
                    if (doc.exists) {
                        name = doc.data().name
                        tmp.push({ id: id, name: name })
                        setTournaments(tmp)
                    }
                })
            }
        }
    }

    const getView = () => {
        if (loading) {
            return <CircularProgress />
        } else {
            return <div>
                {emptyMatch()}
                {playerMatchs.map((item, index) => (
                    <div>
                        <ScoreDialog key={index} open={item.open} close={() => closeDialog(index)} match={item} reload={getMatch} type={item.type} view='perso' />
                        <div>{getTournamentName(item)}</div>
                        <Match key={index} match={item} />
                        {btnGrp(item, index)}
                    </div>
                ))}
            </div>
        }
    }
    return (
        getView()
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3)
    },
    btn: {
        margin: theme.spacing(1),
        textAlign: 'end'
    }
}));

export default Matchs;