import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { app, SecondApp } from '../../firebase/firebase';
import { useParams } from "react-router-dom";
import { members } from '../../assets/members';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function CreatePlayer() {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [firstname, setFirstname] = useState('');
    const [licence, setLicence] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('Membre');
    const [count, setCount] = useState(0);
    let { id } = useParams();
    console.log('id:' + id)

    useEffect(() => {
        if (id !== undefined) {
            app.firestore().collection('players').doc(id).get().then(snapshot => {
                var data = snapshot.data()
                setName(data.name)
                setFirstname(data.firstname)
                setLicence(data.licence)
                setRole(data.role)
            })
        }
    }, [])

    /*
    useEffect(() => {
        loadPlayers()
    }, [count])
    /*
    *   Start importation code
    * --- BUG: Problème de synchronisation entre le nouvel utilisateur et le player. 
    * Le res.user.uid est parfois identique à plusieurs reprises donc override le joueur précédent...
    */
    const loadPlayers = () => {
        var item = members[count];
        if (item.email !== undefined && item.licence !== undefined) {
            SecondApp.auth().createUserWithEmailAndPassword(item.email, '123456').then(res => {
                console.log(res.user.uid)
                app.firestore().collection('players').doc(res.user.uid).set({
                    name: item.name,
                    firstname: item.firstname,
                    licence: item.licence,
                    role: 'Membre'
                }).then(() => {
                    console.log('ok')
                }).catch(err => {
                    console.error(err)
                })
                //SecondApp.auth().signOut();
            }).catch(err => {
                console.error(err)
            })
        }
    }
    /*
    * End importation code
    */

    const createUser = () => {
        SecondApp.auth().createUserWithEmailAndPassword(email, password).then(res => {
            createPlayer(res.user.uid)
        }).catch(err => {
            alert('Oups: ' + err.message)
            console.error(err)
        })
    }
    const updateUser = () => {
        app.firestore().collection('players').doc(id).update({
            name: name,
            firstname: firstname,
            licence: licence,
            role: role
        }).then(res => {
            alert('Le joueur a bien été mis à jour')
        }).catch(err => {
            alert('Oups: ' + err.message)
            console.error(err)
        })

    }

    const createPlayer = (uid) => {
        app.firestore().collection('players').doc(uid).set({
            name: name,
            firstname: firstname,
            licence: licence,
            role: role
        }).then(res => {
            alert('Joueur créé avec succès')
            SecondApp.auth().signOut()
        })
            .catch(err => {
                console.error(err)
                alert(err.message)
            })
    }

    //is update user, cannot update email and password
    const getView = () => {
        if (id === undefined) {
            return <div className={classes.root}>
                <TextField label="Nom" variant="outlined" className={classes.txt} onChange={event => setName(event.currentTarget.value)} />
                <TextField label="Prénom" variant="outlined" className={classes.txt} onChange={event => setFirstname(event.currentTarget.value)} />
                <TextField label="Numéro de licence" variant="outlined" className={classes.txt} onChange={event => setLicence(event.currentTarget.value)} />
                <Select
                    value={role}
                    onChange={event => setRole(event.target.value)}
                    className={classes.txt}
                >
                    <MenuItem value={'Membre'}>Membre</MenuItem>
                    <MenuItem value={'Responsable'}>Responsable</MenuItem>
                    <MenuItem value={'Administrateur'}>Administrateur</MenuItem>
                </Select>
                <TextField label="Email" variant="outlined" className={classes.txt} onChange={event => setEmail(event.currentTarget.value)} />
                <TextField label="Mot de passe" variant="outlined" className={classes.txt} onChange={event => setPassword(event.currentTarget.value)} />
                <Button variant="contained" color="primary" onClick={() => createUser()}>Créer</Button>
            </div>
        } else {
            return <div className={classes.root}>
                <TextField value={name} variant="outlined" className={classes.txt} onChange={event => setName(event.currentTarget.value)} />
                <TextField value={firstname} variant="outlined" className={classes.txt} onChange={event => setFirstname(event.currentTarget.value)} />
                <TextField value={licence} variant="outlined" className={classes.txt} onChange={event => setLicence(event.currentTarget.value)} />
                <Select
                    value={role}
                    onChange={event => setRole(event.target.value)}
                    className={classes.txt}
                >
                    <MenuItem value={'Membre'}>Membre</MenuItem>
                    <MenuItem value={'Responsable'}>Responsable</MenuItem>
                    <MenuItem value={'Administrateur'}>Administrateur</MenuItem>
                </Select>
                <Button variant="contained" color="primary" onClick={() => updateUser()}>Update</Button>
            </div>
        }
    }
    //<Button onClick={() => loadPlayers()}>Import players</Button>
    return (
        <div>
            {getView()}
        </div>
    )
}
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    txt: {
        margin: theme.spacing(2)
    }
}));

export default CreatePlayer;