import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import ResponsiveDrawer from './components/drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Players from './views/players';
import Tournaments from './views/tournaments';
import Matchs from './views/matchs';
import Pools from './views/pools';
import Scores from './views/scores';
import CreateTournament from './views/admin/createTournament';
import CreatePool from './views/admin/createPool';
import CreateDraw from './views/admin/createDraw';
import CreatePlayer from './views/admin/createPlayer';
import { UserContext } from './context/UserContext';
import { FirebaseContext } from './context/FirebaseContext';
import Login from './views/login';
import Logout from './views/logout';
import * as firebase from 'firebase';
import FirebaseConfig from './components/config';
import Profile from './views/profile';
import { UserContextProvider } from './context/UserContext';
import Draw from './views/draw';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'stretch'
  },
  toolbar: theme.mixins.toolbar,
}));

function App() {
  //firebase.initializeApp(FirebaseConfig)
  const theme = useTheme();
  const classes = useStyles();
  /* const setCurrentUser = (uid) => {
     setUser({...user, uid:uid})
   }
   const [user, setUser] = useState({ uid: '', setUid: setCurrentUser });*/

  return (
    <div className={classes.root}>
      <Router>
        <UserContextProvider>
          <ResponsiveDrawer />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route path="/logout">
                <Logout />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/players">
                <Players />
              </Route>
              <Route path="/matchs">
                <Matchs />
              </Route>
              <Route path="/pools/:id/scores">
                <Scores />
              </Route>
              <Route path="/pools/:name/:id/:refAdmin">
                <Pools />
              </Route>
              <Route path="/createPool/:tournamentId">
                <CreatePool />
              </Route>
              <Route path="/createDraw/:tournamentId">
                <CreateDraw />
              </Route>
              <Route path="/createTournament">
                <CreateTournament />
              </Route>
              <Route path="/createPlayer/:id">
                <CreatePlayer />
              </Route>
              <Route path="/createPlayer">
                <CreatePlayer />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/draw/:name/:id/:refAdmin">
                <Draw />
              </Route>
              <Route path="/">
                <Tournaments />
              </Route>
            </Switch>
          </main>
        </UserContextProvider>
      </Router>

    </div>
  );
}

export default App;
